@use '@angular/material' as mat;
@import "src/styles/colors.scss";
@import '~normalize.css';


p {
 font-family: "roboto", serif;
}

html {
  font-family: Roboto, sans-serif;
  font-size: 16px;
  line-height: 1.5625;
  color: #58595b;
}

.color-primary {
  color: $primary !important;
}

.color-secondary {
  color: $secondary;
}

.backgroud-primary {
  background-color: $primary;
}

.backgroud-secondary {
  background-color: $secondary;
}


html, body { height: 100%; }
body { font-family: Roboto, "Helvetica Neue", sans-serif; }

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

.error-snackbar {
  color: $errorColor;
  background-color: $errorBackgroundColor;
}

.success-snackbar {
  color: $successColor;
  background-color: $successBackgroundColor;
}

.mat-tab-label-active {
  color: $primary;
}
.mat-mdc-tab-group.mat-primary .mat-ink-bar, .mat-mdc-tab-nav-bar.mat-primary .mat-ink-bar{
  background: $primary;
}

.mat-mdc-icon-button.mat-primary {
  color: $primary !important;
}

.dense{
  @include mat.all-component-densities(-3);
}

.full-width {
  width: 100%;
}

.selectable-table-row {
  cursor: pointer;

  &:hover {
    background-color: #e1e1e110;
  }
}
.important-text {
  font-weight: 500;
  color: $headerTextColor;
}

$primary-palette: (
    50 : #fef0e4,
    100 : #fcdabc,
    200 : #fac190,
    300 : #f8a863,
    400 : #f79541,
    500 : #f58220,
    600 : #f47a1c,
    700 : #f26f18,
    800 : #f06513,
    900 : #ee520b,
    A100 : #ffffff,
    A200 : #ffece5,
    A400 : #ffc6b2,
    A700 : #ffb498,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #000000,
        500 : #ffffff,
        600 : #000000,
        700 : #000000,
        800 : #000000,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);

$accent-palette: (
    50 : #e0f0f2,
    100 : #b3d9de,
    200 : #80bfc9,
    300 : #4da5b3,
    400 : #2692a2,
    500 : #007f92,
    600 : #00778a,
    700 : #006c7f,
    800 : #006275,
    900 : #004f63,
    A100 : #93e5ff,
    A200 : #60d9ff,
    A400 : #2dccff,
    A700 : #14c6ff,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #ffffff,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);

$primary-mat-palette: mat.define-palette($primary-palette);
$accent-mat-palette: mat.define-palette($accent-palette);
$my-theme: mat.define-light-theme((
 color: (
   primary: $primary-mat-palette,
   accent: $accent-mat-palette,
 ),

 typography: mat.define-typography-config(),
 density: 0,
));
@include mat.core-theme($my-theme);
@include mat.all-component-themes($my-theme);
