$primary: #f58220;
$disabled: #e1e1e1;
$accent: #007f92;
$secondary: white;
$matIconColor: black;
$errorBackgroundColor: #ffbaba;
$errorColor: #d8000c;
$successBackgroundColor: #dff2bf;
$successColor: #4f8a10;
$headerTextColor: rgba(0, 0, 0, 0.87);

// moet nog gerefactord worden om volledig geintegreerd te zijn in material theme
// http://mcg.mbitson.com/#!?mcgpalette0=%233f51b5 tooltje om te genereren
.mat-primary .mdc-button__label {
  color: white;
}
